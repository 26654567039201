@import "@/styles/mixins";

:import("@/components/ui/ControlInput/styles.module.scss") {
  control-input: control-input;
  control-input__input: control-input__input;
}

:import("@/components/ui/Highlighter/styles.module.scss") {
  highlighter: highlighter;
}

.control-multi {
  $element-height: 40px;

  position: relative; background-color: var(--color-white); cursor: default; overflow: hidden; border-radius: var(--radius-small);
  transition: box-shadow var(--ui-speed) var(--ui-easing) 0ms;
  box-shadow: inset 0 0 0 1px var(--color-neutral-400);

  &.focus { box-shadow: inset 0 0 0 1px var(--color-focus), 0 0 0 4px rgba(#0B6CF4, 0.2) !important; }

  &:hover { box-shadow: inset 0 0 0 1px var(--color-neutral-800); }

  &.disabled,
  &.readonly {
    cursor: default;

    &:before { box-shadow: none; background-color: currentColor; opacity: 0.05; }
  }

  &.has-error {
    &:before { box-shadow: inset 0 0 0 1px var(--color-danger); }
  }

  &__holder {
    display: flex; flex-direction: row; flex-wrap: nowrap; align-items: stretch; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;
  }

  &__placeholder {
    margin: 0; padding: 0 12px; background-color: transparent; border: none; box-shadow: none;
    border-radius: var(--radius-small); outline: none; appearance: none; text-align: inherit; flex: 0 1 auto; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;

    &::placeholder { color: rgba(0, 0, 0, 0.3); }

    &::-ms-clear { display: none; appearance: none; width: 0; height: 0; opacity: 0; }
  }

  &__icon {
    padding: 0; display: flex; align-items: center; justify-content: center; width: $element-height; box-sizing: border-box; color: inherit; flex: 0 0 auto;

    path { fill: currentColor; }
  }

  &__loader {
    display: flex; flex-direction: row; flex-wrap: nowrap;
    flex: 0 0 auto; pointer-events: none; user-select: none; position: relative; line-height: $element-height; min-width: $element-height;
    padding: 0; align-items: center; justify-content: center;
  }

  &__arrow {
    margin-left: auto; padding: 0; display: flex; align-items: center; justify-content: center; width: $element-height; box-sizing: border-box; color: inherit; flex: 0 0 auto; pointer-events: none;

    path { fill: currentColor; }
  }

  &__count {
    margin: calc(($element-height - 24px) / 2) auto calc(($element-height - 24px) / 2) 0; line-height: 24px; background-color: var(--color-primary-500); color: var(--color-white); border-radius: var(--radius-small);
    padding: 0 4px; min-width: 24px; text-align: center; box-sizing: border-box; flex: 0 0 auto;
  }

  &__drop {
    position: absolute; left: 0; top: calc(100% + 4px); z-index: 200; overflow: hidden; border-radius: var(--radius-default);
    display: grid; grid-template-rows: 0fr; transition: grid-template-rows calc(var(--ui-speed) / 2) var(--ui-easing) 0ms;
    box-shadow: 0 4px 16px 0 #0216311F; background-color: var(--color-white);

    @include mobile {
      max-width: calc(100vw - var(--gridunit-small) - var(--gridunit-small)) !important;
    }

    &__inner {
      max-height: 350px; height: 100%; overflow: hidden; box-sizing: border-box;
      @include overflow(false);
      @include scrollbars(false);
    }

    &__list {
      padding: 8px 12px;
    }

    .control-input {
      margin-bottom: 10px; background-color: var(--color-primary-50); border-radius: 20px;

      &:before,
      &:after { border-radius: 20px; }

      &__input { border-radius: 20px; padding-left: 20px !important; }
    }

    &.is-open { grid-template-rows: 1fr; }
  }

  &__mobile {
    &__list {
      margin-top: var(--gridunit-small);
    }

    .control-input {
      margin-top: var(--gridunit-small); background-color: var(--color-primary-50); border-radius: 20px;

      &:before,
      &:after { border-radius: 20px; }

      &__input { border-radius: 20px; padding-left: 20px !important; }
    }

    .control-multi__item {
      &:before { display: none; }

      &__options {
        position: relative;

        &:before { content: ""; position: absolute; left: 8px; top: 10px; width: 1px; bottom: 0; background-color: currentColor; }

        &:has(> .control-multi__item-mobile + .control-multi__item-mobile):before { display: none; }
      }

      &__options > .control-multi__item-mobile {
        margin-left: -24px;

        &:before { content: ""; position: absolute; left: 8px; top: 10px; width: 1px; bottom: calc(var(--gridunit-tiny) * -1); background-color: var(--color-white); z-index: -1; }
      }

      &:last-child > .control-multi__item__options:before { display: none; }
    }

  }

  &__item {
    position: relative;

    &.is-open:not(:last-child) { padding-bottom: 12px; }

    &:before { content: ""; position: absolute; left: 8px; top: 30px; bottom: 4px; width: 1px; background-color: var(--color-neutral-200); }

    &:last-child:before { display: none; }

    &__element {
      display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start; gap: var(--gridunit-tiny);

      .highlighter { font-weight: 400 !important; color: var(--color-highlight) !important; }

      &__checkbox { flex: 0 0 auto; margin-top: 4px; }

      &__label {
        flex: 1 1 auto; overflow: hidden; text-overflow: ellipsis; padding: 2px 0; position: relative;
        display: flex; flex-direction: row; flex-wrap: nowrap; gap: var(--gridunit-tiny);

        &.has-options {
          padding-left: calc(16px + var(--gridunit-tiny)); cursor: pointer;

          &:hover { color: var(--color-link); }

          &:before { content: ""; position: absolute; left: 2px; top: 14px; width: 13px; height: 1px; background-color: var(--color-primary-900); }

          &:after { content: ""; position: absolute; left: 8px; top: 8px; width: 1px; height: 13px; background-color: var(--color-primary-900); }
        }

        &.is-open {
          &:after { display: none; }
        }
      }

      @include mobile {
        border-top: 1px solid var(--color-neutral-200); padding: var(--gridunit-tiny) 0;
      }
    }

    &__options {
      position: relative; padding-left: 24px;
    }

    &__label { flex: 1 1 auto; overflow: hidden; text-overflow: ellipsis; line-height: 24px; }

    &__count { flex: 0 0 auto; line-height: 24px; color: var(--color-primary-500); }
  }

  &__item-mobile {
    padding: 8px; cursor: pointer; position: relative; z-index: 0;
    display: flex; flex-direction: row; flex-wrap: nowrap; gap: var(--gridunit-tiny);

    &:after { content: ""; position: absolute; inset: 0; box-shadow: 0 4px 8px 0 #0216311F; border-radius: 20px; background-color: var(--color-white); z-index: -1; }

    &__check {
      flex: 0 0 24px; width: 24px; height: 24px; position: relative; display: flex; align-items: center; justify-content: center;

      &:before {
        content: ""; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); background-color: var(--color-neutral-200); width: 8px; height: 8px; border-radius: 50%;
        transition: all var(--ui-speed) var(--ui-easing) 0ms;
      }

      &:has(svg) {
        &:before { width: 24px; height: 24px; background-color: var(--color-primary-500); }
      }

      path { fill: var(--color-white); }
    }

    .highlighter { font-weight: 400 !important; color: var(--color-highlight) !important; }
  }

  &__item-mobile + &__item-mobile {
    margin-top: var(--gridunit-tiny);
  }

  &__item-mobile + &__item {
    margin-top: var(--gridunit-tiny);
  }

  // Sizes

  &.size-default & {
    &__holder { height: $element-height; line-height: $element-height; }

    &__arrow { width: $element-height; }
  }

  @media print { display: none; }

  &-mobile {
    border-bottom: 1px solid var(--color-neutral-200); height: $element-height; line-height: $element-height; cursor: pointer;
    display: flex; flex-direction: row; flex-wrap: nowrap; align-items: stretch; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;

    &:last-child { border-bottom: none; }
  }

  &-mobile & {
    &__placeholder { padding-left: 0; }
  }
}
